.creditRequestHeader {
  width: 100%;
  border-bottom-color: #f1f2f2;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  height: 81px;

  .creditRequestTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    padding: 26px;
  }
  .cancelButton {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  .submitButton {
    position: absolute;
    right: 30px;
    top: 15px;
  }
}
